import Api from '@/services/Api'

export default {
  findAll (pagination) {
    return Api().post('empresaClientes', pagination)
  },
  insert (empresaCliente) {
    return Api().post('empresaCliente', empresaCliente)
  },
  update (empresaCliente) {
    return Api().put('empresaCliente', empresaCliente)
  },
  delete (empresaCliente) {
    return Api().delete(`empresaCliente?id=${empresaCliente.id}`, empresaCliente)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('empresaClientesParam', param)
  },
  findById (empresaCliente) {
    const url = `empresaCliente/${empresaCliente}`
    return Api().get(url)
  },
  findByParamAutoComplete (param) {
    const texto = encodeURI(param)
    return Api().get('empresasClientesParamAutoComeplete?param=' + texto)
  }
}
