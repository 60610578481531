<template>
  <crud-list
    :titulo-pesquisa="tituloPesquisa"
    :headers="headers"
    :loading="loading"
    :items="items"
    :total-registros="totalRegistros"
    :pagination.sync="pagination"
    :hide-headers="$vuetify.breakpoint.smAndDown"
    @buscar-event="buscar"
    @novo-event="novo"
  >
    <template slot="botaoDepoisAcaoTitulo">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="v-btn--simple"
            icon
            v-bind="attrs"
            @click="mostraFiltro = !mostraFiltro"
            v-on="on"
          >
            <v-icon>{{ mostraFiltro? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t( mostraFiltro ? 'padrao_esconder_filtro':'padrao_exibir_filtro') }}</span>
      </v-tooltip>
    </template>
    <template slot="antesDaDataTable">
      <v-row v-show="mostraFiltro">
        <v-col
          v-if="!idEmpresaCliente || idEmpresaCliente < 0"
          cols="12"
        >
          <empresa-cliente-auto-complete
            v-model="empresaClienteModel"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <v-select
            v-model="filtro.idStatusEvento"
            :label="$t('EventosList.statusEvento')"
            :items="listaStatusEvento"
            item-value="id"
            item-text="nomeStatusEvento"
            required
            prepend-icon="mdi-format-list-bulleted-type"
          />
        </v-col>
        <v-col
          :cols="12"
          sm="6"
        >
          <v-autocomplete
            v-model="autoCompleteClienteModel"
            :readonly="!empresaClienteModelId"
            :items="autoCompleteClienteItens"
            :loading="autoCompleteClienteLoading"
            :search-input.sync="autoCompleteClienteCampoBusca"
            :filter="filterClienteAc"
            :label="$t('EventosList.idCliente')"
            hide-no-data
            item-text="nome"
            item-value="id"
            :placeholder="$t('padrao_digite_para_buscar')"
            prepend-icon="mdi-database-search"
            return-object
            clearable
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="filtro.local"
            :label="$t('EventosList.local')"
            prepend-icon="mdi-format-list-bulleted-type"
          />
        </v-col>
        <v-col
          md="3"
          cols="5"
        >
          <v-menu
            ref="menuDataEvento"
            v-model="menuDataEvento"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataEventoFormatted"
                :label="$t('EventosList.dataEvento')"
                persistent-hint
                readonly
                prepend-icon="mdi-calendar-text"
                append-icon="mdi-close"
                @blur="filtro.dataEvento = parseDate(dataEventoFormatted)"
                v-on="on"
                @click:append="filtro.dataEvento = null"
              />
            </template>
            <v-date-picker
              v-model="filtro.dataEvento"
              no-title
              dark
              prepend-icon="mdi-calendar-text"
              @input="menuDataEvento = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="5"
          md="3"
        >
          <v-menu
            ref="menuDataEventoFim"
            v-model="menuDataEventoFim"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataEventoFimFormatted"
                :label="$t('EventosList.dataEventoFim')"
                persistent-hint
                readonly
                append-icon="mdi-close"
                prepend-icon="mdi-calendar-text"
                @blur="filtro.dataEventoFim = parseDate(dataEventoFimFormatted)"
                v-on="on"
                @click:append="filtro.dataEventoFim = null"
              />
            </template>
            <v-date-picker
              v-model="filtro.dataEventoFim"
              no-title
              dark
              prepend-icon="mdi-calendar-text"
              @input="menuDataEventoFim = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="2"
          sm="2"
        >
          <v-btn
            class="v-btn--simple"
            color="primary"
            outlined
            :icon="$vuetify.breakpoint.smAndDown"
            @click="buscar"
          >
            <span v-if="!$vuetify.breakpoint.smAndDown">{{ $t('padrao_pesquisar') }}</span>
            <v-icon>{{ 'mdi-magnify' }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <hr v-show="mostraFiltro">
    </template>
    <template
      slot="items"
      slot-scope="{ item }"
    >
      <tr>
        <td
          class="hidden-sm-and-down justify-left btnEditar cursorPointer"
          @click="editar(item.item)"
        >
          {{ item.item.tipoEvento }}
          <v-icon small>
            mdi-pencil
          </v-icon>
        </td>
        <td class="hidden-sm-and-down justify-left">
          {{ item.item.nomeCliente }}
          <div v-if="item.item.telefone1">
            <v-icon
              small
              class="mr-2"
            >
              mdi-phone-classic
            </v-icon>
            {{ item.item.telefone1 }}
          </div>
        </td>
        <td class="hidden-sm-and-down justify-left">
          {{ item.item.nomeClientePagador }}
        </td>
        <td
          v-data-formatada
          class="hidden-sm-and-down justify-left"
        >
          {{ item.item.dataEvento }}
        </td>
        <td class="hidden-sm-and-down justify-left">
          {{ item.item.horaEvento }}
        </td>
        <td
          class="hidden-md-and-up justify-center"
          @click="editar(item.item)"
        >
          <div class="tipoEvento">
            <v-icon
              small
              class="mr-2"
            >
              mdi-pencil
            </v-icon>
            {{ item.item.tipoEvento }}
          </div>
          <div
            v-if="item.item.nomeCliente"
            class="nomeCliente"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-database-search
            </v-icon>
            {{ item.item.nomeCliente }}
            <span
              v-if="item.item.telefone1"
              class="ml-2"
            >
              <v-icon
                small
                class="mr-2"
              >
                mdi-phone-classic
              </v-icon>
              {{ item.item.telefone1 }}
            </span>
          </div>
          <div
            v-if="item.item.nomeClientePagador"
            class="nomeClientePagador"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-database-search
            </v-icon>
            {{ item.item.nomeClientePagador }}
          </div>
          <div class="dataEvento">
            <v-icon
              small
              class="mr-2"
            >
              mdi-calendar-text
            </v-icon>
            <span v-data-formatada>{{ item.item.dataEvento }}</span>
            {{ item.item.horaEvento }}
          </div>
          <!-- <div class="telefone1">
            <v-icon
              v-if="item.item.telefone1"
              small
            >
              mdi-phone-classic
            </v-icon>
            {{ item.item.telefone1 }}
          </div> -->
        </td>
        <td v-if="!idEmpresaCliente">
          <span
            v-if="item.item.EmpresaCliente !== null && item.item.EmpresaCliente !== undefined"
          >{{ item.item.EmpresaCliente.nome }}</span>
        </td>
      </tr>
    </template>
  </crud-list>
</template>

<script>
  import EventosService from "@/services/EventosService"
  import { mapState } from "vuex"
  import colunaEmpresaCliente from "@/utils/EmpresaClienteUtils"
  import EmpresaClienteAutoComplete from "@/views/EmpresaClienteAutoComplete"
  import StatusEventoService from '../services/StatusEventoService'
  import DateUtils from "../utils/DateUtils"
  import EmpresaClientesService from '../services/EmpresaClientesService'
  import ClientesService from '../services/ClientesService'

  export default {
    components: {
      EmpresaClienteAutoComplete,
    },
    data: () => ({
      empresaClienteModel: null,
      empresaClienteModelId: null,
      totalRegistros: 0,
      pagination: {},
      items: [],
      listaStatusEvento: [],
      clienteModelId: null,
      autoCompleteClienteModel: null,
      autoCompleteClienteItens: [],
      autoCompleteClienteLoading: false,
      autoCompleteClienteCampoBusca: '',
      loading: false,
      mostraFiltro: true,
      menuDataEvento: false,
      menuDataEventoFim: false,
      started: false,
      filtro: {
        dataEvento: null,
        dataEventoFim: null,
        idEmpresaCliente: null,
        idStatusEvento: null,
        idCliente: null,
        local: null
      },
    }),
    computed: {
      ...mapState(["idEmpresaCliente"]),
      statusEventoVazio: function() {
        return {
          nomeStatusEvento: this.$i18n.t('padrao_todos'),
          id: null
        }
      },
      tituloPesquisa: function () {
        return this.$i18n.t("EventosForm.tituloPesquisa");
      },
      dataEventoFormatted: function () {
        return DateUtils.formatDate(this.filtro.dataEvento);
      },
      dataEventoFimFormatted: function () {
        return DateUtils.formatDate(this.filtro.dataEventoFim);
      },
      headers: function () {
        const retorno = [
          {
            text: this.$i18n.t("EventosForm.tipoEvento"),
            value: "tipoEvento",
            sortable: false,
          },
          {
            text: this.$i18n.t("EventosForm.nomeCliente"),
            value: "nomeCliente",
            class: "hidden-sm-and-down",
            sortable: false,
          },
          {
            text: this.$i18n.t("EventosForm.nomeClientePagador"),
            value: "nomeClientePagador",
            class: "hidden-sm-and-down",
            sortable: false,
          },
          {
            text: this.$i18n.t("EventosForm.dataEvento"),
            value: "dataEvento",
            class: "hidden-sm-and-down",
            sortable: false,
          },
          {
            text: this.$i18n.t("EventosForm.horaEvento"),
            value: "horaEvento",
            class: "hidden-sm-and-down",
            sortable: false,
          },
        ];

        if (!this.idEmpresaCliente) {
          retorno.push(
            colunaEmpresaCliente(this.$i18n.t("EmpresaClientesForm.tituloPagina"))
          );
        }

        return retorno;
      },
    },
    watch: {
      empresaClienteModel(val) {
        if (val === null || !val) {
          this.empresaClienteModelId = null;
          this.listaStatusEvento = [this.statusEventoVazio]
          this.autoCompleteClienteModel= null
          this.autoCompleteClienteItens= []
          this.autoCompleteClienteLoading= false
          this.autoCompleteClienteCampoBusca= ''
        } else {
          this.empresaClienteModelId = val.id;
          this.listaStatusEvento = [this.statusEventoVazio]
          this.preencheStatusEventos()
        }
      },
      autoCompleteClienteModel(val) {
        if (val === null || !val) {
          this.clienteModelId = null
          this.filtro.idCliente = null
        } else {
          this.clienteModelId = val.id
          this.filtro.idCliente = val.id
        }
      },
      idEmpresaCliente (val) {
        if(val !== 0){
          this.buscar()
        }
      },
      autoCompleteClienteCampoBusca (val) {
        if (!val) {
          return
        }
        if (val.length < 3) {
          return
        }
        this.buscaCliente(val)
      },
    },
    created() {
      this.$root.$emit("alteraMostraPesquisa", false);
      this.$root._events.pesquisa = [];
      // this.$root.$on("pesquisa", this.efetuarPesquisa);
    },
    mounted() {

      if(localStorage.getItem('pagination-evento-list')){
        this.pagination = JSON.parse(localStorage.getItem('pagination-evento-list'))
      }

      if(localStorage.getItem('filtro-evento-list') != null){
        this.filtro = JSON.parse(localStorage.getItem('filtro-evento-list'))
        this.clienteModelId = this.filtro.idCliente
        if(this.filtro.idEmpresaCliente && this.filtro.idEmpresaCliente >= 0){
          EmpresaClientesService.findById(this.filtro.idEmpresaCliente)
            .then((res) => {
              this.empresaClienteModel = res.data
              this.started = true
              this.buscar()
            })
            .catch(error => {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao:
                  this.$t('padrao_ocorreu_um_erro_ultima_operacao') +
                  ':\n ->' +
                  error
              })
              this.started = true
            })
        }else{
          this.started = true
          this.buscar()
        }
        if(this.filtro.idCliente && this.filtro.idCliente > 0){
          ClientesService.findById({id: this.filtro.idCliente})
            .then((res) => {
              this.autoCompleteClienteModel = res.data
              this.autoCompleteClienteItens = [res.data]
              this.buscar()
            })
        }
      }else{
        const dt = new Date();
        const day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        let month = dt.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        this.filtro.dataEvento = `${dt.getFullYear()}-${month}-${day}`
        this.started = true
        this.buscar()
      }

    },
    methods: {

      filterClienteAc (item, queryText, itemText) {
        return true
      },
      buscaCliente (param) {
        this.autoCompleteClienteLoading = true
        ClientesService.buscaClientesPorParam(
          param,
          this.filtro.idEmpresaCliente
        )
          .then((clientes) => {
            this.autoCompleteClienteItens = clientes.data
          })
          .catch((error) => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro na busca dos clientes ' + error
            })
          })
          .finally(() => {
            this.autoCompleteClienteLoading = false
          })
      },
      // efetuarPesquisa(pesquisa) {},
      preencheStatusEventos(){
        StatusEventoService.findAll(null, this.empresaClienteModelId).then(
          (listaStatusEvento) => {
            if (
              listaStatusEvento &&
              listaStatusEvento.data &&
              listaStatusEvento.data.rows
            ) {
              this.listaStatusEvento.push(...listaStatusEvento.data.rows)
            }
          }
        )
      },
      preencheClientes(){
        ClientesService.findAll(null, this.empresaClienteModelId).then(
          (listaCliente) => {
            if (
              listaCliente &&
              listaCliente.data &&
              listaCliente.data.rows
            ) {
              this.listaCliente.push(...listaCliente.data.rows)
            }
          } 
        )
      },
      buscar () {
        if(!this.idEmpresaCliente || this.idEmpresaCliente === 0){
          return
        }
        this.items = []
        if(!this.started){
          return
        }

        this.loading = true
        this.filtro.idEmpresaCliente =
          this.empresaClienteModelId || this.idEmpresaCliente

        this.filtro.idCliente = this.clienteModelId

        const str = JSON.stringify(this.filtro)
        localStorage.setItem('filtro-evento-list', str)
        localStorage.setItem('pagination-evento-list', JSON.stringify(this.pagination))

        EventosService.findByParam(this.filtro, this.pagination)
          .then((registros) => {
            if (registros && registros.data && registros.data.rows) {
              this.items = registros.data.rows;
            }
            if (registros && registros.data) {
              this.totalRegistros = registros.data.count;
            } else {
              this.totalRegistros = 0;
            }
          })
          .catch((err) => {
            this.$root.$emit("mostra_dialogo_erro", {
              dialogoDescricao: "Erro ao buscar os registros: \n" + err,
            })
          })
          .finally(() => {
            this.loading = false;
          })
      },
      parseDate(date) {
        return DateUtils.parseDate(date);
      },
      novo() {
        this.$router.push("/eventoForm");
      },
      editar(item) {
        if (item && item.id) {
          const url = "/eventoForm/" + item.id;
          this.$router.push(url);
        }
      },
    },
  };
</script>

<style>
</style>
