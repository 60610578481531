<template>
  <v-autocomplete
    :value="value"
    :items="autoCompleteItens"
    :loading="autoCompleteLoading"
    :search-input.sync="autoCompleteCampoBusca"
    :filter="filterAc"
    :label="$t('EmpresaClientesForm.tituloPagina')"
    hide-no-data
    item-text="nome"
    item-value="id"
    :placeholder="$t('padrao_digite_para_buscar')"
    prepend-icon="mdi-database-search"
    return-object
    :clearable="clearable"
    :readonly="readonly"
    @input="disparaEvento($event)"
    @click:clear="disparaEventoLimpaCampo($event)"
  />
</template>

<script>

  import EmpresaClientesService from '@/services/EmpresaClientesService'

  export default {
    name: 'EmpresaClienteAutoComplete',
    props: {
      value: {
        type: Object,
        default: null
      },
      readonly: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        autoCompleteItens: [],
        autoCompleteLoading: false,
        autoCompleteCampoBusca: ''
      }
    },
    watch: {
      value (val) {
        if (val !== null && this.autoCompleteItens) {
          this.autoCompleteItens.push(val)
        }
      },
      autoCompleteCampoBusca (val) {
        if (!val) {
          return
        }
        if (val.length < 3) {
          return
        }
        this.buscaEmpresaCliente(val)
      }
    },
    methods: {
      disparaEventoLimpaCampo (event) {
        this.$emit('click:clear', event)
      },
      disparaEvento (event) {
        this.$emit('input', event)
      },
      buscaEmpresaCliente (param) {
        this.autoCompleteLoading = true
        EmpresaClientesService.findByParamAutoComplete(param)
          .then(items => {
            this.autoCompleteItens = items.data
          })
          .catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ':\n ->' + error
            })
          })
          .finally(() => {
            this.autoCompleteLoading = false
          })
      },
      filterAc (item, queryText, itemText) {
        return true
      }
    }
  }
</script>
