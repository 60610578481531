import Api from '@/services/Api'

export default {
  findAll (pagination, idEmpresaCliente) {
    const param = {
      idEmpresaCliente, pagination
    }
    return Api().post('eventos', param)
  },
  insert (evento) {
    return Api().post('evento', evento)
  },
  update (evento) {
    return Api().put('evento', evento)
  },
  delete (evento) {
    return Api().delete(`evento?id=${evento.id}`, evento)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('eventosParam', param)
  },
  findById (evento) {
    const url = `evento/${evento.id}`
    return Api().get(url)
  },
  gerarPdf(param) {
    const requestOptions ={ responseType: 'arraybuffer' };
    return Api().post(`evento/gerar-pdf`,{param}, requestOptions).then((response) => {
      const responseBlob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(responseBlob);
      window.open(url);
    });
  }
}
