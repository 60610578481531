import Api from '@/services/Api'

export default {
  findAll (pagination, idEmpresaCliente) {
    const param = {
      idEmpresaCliente, pagination
    }
    return Api().post('clientes', param)
  },
  insert (cliente) {
    return Api().post('cliente', cliente)
  },
  update (cliente) {
    return Api().put('cliente', cliente)
  },
  delete (cliente) {
    return Api().delete(`cliente?id=${cliente.id}`, cliente)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('clientesParam', param)
  },
  findById (cliente) {
    const url = `cliente/${cliente.id}`
    return Api().get(url)
  },
  buscaClientesPorParam (param, idEmpresaCliente) {
    return Api().get(`clientesPorParam?idEmpresaCliente=${idEmpresaCliente}&param=${param}`)
  },
  countTotalPorEmpresaCliente (idEmpresaCliente) {
    const url = `cliente/contar/${idEmpresaCliente}`
    const api = Api()
    return api.get(url)
  }

}
