import Api from '@/services/Api'

export default {
  findAll (pagination, idEmpresaCliente) {
    const param = {
      idEmpresaCliente, pagination
    }
    return Api().post('statusEventos', param)
  },
  insert (statusEvento) {
    return Api().post('statusEvento', statusEvento)
  },
  update (statusEvento) {
    return Api().put('statusEvento', statusEvento)
  },
  delete (statusEvento) {
    return Api().delete(`statusEvento?id=${statusEvento.id}`, statusEvento)
  },
  findById (statusEvento) {
    const url = `statusEvento/${statusEvento.id}`
    return Api().get(url)
  },
  findByParam (param, pagination) {
    param = {
      param, pagination
    }
    return Api().post('statusEventosParam', param)
  }
}
